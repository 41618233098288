import { Link } from "gatsby";
import * as React from "react"
import Footer from "../components/Footer"
import Header from "../components/Header"
import "../styles/index.scss";

// markup
const NotFoundPage = () => {
  return (
    <main>
      <Header />
      <section className="notfound wrapper">
        <h1>404 Seite nicht gefunden</h1>
        <p>Die von Ihnen angeforderte Seite existiert nicht.</p>
        <Link to="/" className="btn">Zurück zur Startseite</Link>
      </section>
      <Footer />
    </main>
  )
}

export default NotFoundPage
